<style lang="less" scoped>
.order {
    height: 100%;
}
</style>
<template>
    <div class="content">
        <div class="hd">
            <el-date-picker
                size="small"
                v-model="timeSlot"
                type="daterange"
                range-separator="-"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :picker-options="pickerOptions"
                @change="changeDate"
            >
            </el-date-picker>
        </div>
        <div class="table_box">
            <el-table :data="list" style="width: 100%" height="100%">
                <el-table-column prop="id" label="订单号"> </el-table-column>
                <el-table-column
                    prop="consumptionPoint"
                    label="点数"
                    width="180"
                >
                </el-table-column>
                <el-table-column prop="project" label="用途"> </el-table-column>
                <el-table-column prop="createTime" label="时间" width="180">
                    <template slot-scope="scope">
                        <div>
                            {{
                                moment(scope.row.createTime).format(
                                    "YYYY-MM-DD HH:mm:ss"
                                )
                            }}
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="page">
            <el-pagination
                small
                @size-change="changeSize"
                @current-change="changePage"
                :current-page.sync="currentPage"
                hide-on-single-page
                layout="prev, pager, next, jumper"
                :total="total"
            >
            </el-pagination>
        </div>
    </div>
</template>

<script>
import { getConsumption } from "@/api/user";
import moment from "moment";
export default {
    name: "VideoNewOrder",

    data() {
        return {
            user: "",
            region: "",
            date: "",
            type: "",
            list: [],
            // 分页
            currentPage: 1,
            size: 10,
            total: 0,
            // 时间
            timeSlot: "",
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now() - 8.64e6;
                },
            },
        };
    },

    mounted() {
        console.log(this.currentPage);
    },
    created() {
        let json = {
            currentPage: this.currentPage,
            pageSize: this.size,
        };
        getConsumption(json).then((res) => {
            this.list = res.data.records;
            this.total = res.data.total;
        });
    },

    methods: {
        moment,
        onSubmit() {
            console.log("submit!");
        },
        changePage() {
            let json = {
                currentPage: this.currentPage,
                pageSize: this.size,
            };

            getConsumption(json).then((res) => {
                this.list = res.data.records;
                this.total = res.data.total;
            });
        },
        changeSize() {},
        changeDate(val) {
            console.log(moment(val[0]).format("YYYY-MM-DD"));
            console.log(moment(val[1]).format("x"));
        },
    },
};
</script>

<style lang="less" scoped>
.content {
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    .hd {
        padding: 10px;
    }
    .table_box {
        flex: 1;
        height: 0;
    }
    .page {
        padding: 10px;
    }
}
</style>